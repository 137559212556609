import { FC, useState } from 'react'

import cn from 'clsx'

import { useAppSelector } from '@/shared/model'
import RadioBlock from '@/shared/sports/radio-block/RadioBlock'

import styles from './BetTypeSelect.module.sass'
import { IBetTypeSelect } from './bet-type-select.interface'
import { messages } from '@/i18n/messages'

const tabs = ['singles']

const BetTypeSelect: FC<IBetTypeSelect> = ({
  activeTab,
  setActiveTab,
  setIsBetTypeSelectIsOpened
}) => {
  const [activeRadioBlock, setActiveRadioBlock] = useState(activeTab)

  const onClickCancelButton = () => setIsBetTypeSelectIsOpened(false)
  const onClickApplyButton = () => {
    setActiveTab(activeRadioBlock)
    setIsBetTypeSelectIsOpened(false)
  }

  const { locale } = useAppSelector(state => state.defaultReducer)

  return (
    <div className={styles.betTypeSelect}>
      <div>
        {tabs.map((tab, index) => (
          <RadioBlock
            id={(index + 1).toString()}
            name='bets-settings'
            text={tab}
            defaultChecked={activeTab === index}
            onClick={() => setActiveRadioBlock(index)}
          />
        ))}
      </div>
      <div className={styles.buttonsContainer}>
        <div>
          <button
            className={cn(styles.button, 'buttonBlackHoverRedBorderGold')}
            type='button'
            data-content={messages[locale].cancel}
            onClick={onClickCancelButton}
          />
          <button
            className={cn(styles.button, 'buttonRedHoverBlackBorderGold')}
            type='button'
            data-content={messages[locale].apply}
            onClick={onClickApplyButton}
          />
        </div>
      </div>
    </div>
  )
}

export default BetTypeSelect
