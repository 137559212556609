import { useMutation, useQueryClient } from '@tanstack/react-query'

import AuthService from '@/shared/services/auth/auth.service'

import { IUsers } from '@/shared/types/user.types'

export const useAuthActions = () => {
  const queryClient = useQueryClient()

  const register = useMutation(
    ['register'],
    (data: IUsers.IRegister, ref?: string) => AuthService.register(data),
    {
      onSuccess: () => queryClient.invalidateQueries(['get user data'])
    }
  )

  const registerDemo = useMutation(['register demo user'], () => AuthService.registerDemo(), {
    onSuccess: () => queryClient.invalidateQueries(['get user data'])
  })

  const activation = useMutation(
    ['activation'],
    (data: IUsers.IEmailCode) => AuthService.activation(data),
    {
      onSuccess: () => queryClient.invalidateQueries(['get user data'])
    }
  )

  const login = useMutation(
    ['login'],
    (data: IUsers.IEmailPassword) => AuthService.getTokens(data),
    {
      onSuccess: () => queryClient.invalidateQueries(['get user data'])
    }
  )

  const resetPassword = useMutation(['reset password'], (data: IUsers.IResetPasswordForm) =>
    AuthService.resetPassword(data)
  )

  const sendCodeResetPassword = useMutation(
    ['send code reset password'],
    (data: IUsers.IResetPasswordForm) => AuthService.sendCodeResetPassword(data)
  )

  return { register, registerDemo, activation, login, resetPassword, sendCodeResetPassword }
}
