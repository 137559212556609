import { useEffect, useState } from 'react'

import { useGetScreenResolution } from '@/shared/hooks/useGetScreenResolution'

const ScrollButton = () => {
  const [visible, setVisible] = useState(false)
  const { width } = useGetScreenResolution()

  const toggleVisible = () => {
    const scrolled = window.document.body.scrollTop

    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
    return () => window.addEventListener('scroll', toggleVisible)
  }, [])

  const cart = document.querySelector('.Cart .main')

  return (
    <button
      className='buttonBlackHoverRedBorderGold up'
      style={{
        display: visible ? 'inline' : 'none',
        height: width > 500 ? '50px' : '40px',
        width: width > 500 ? '50px' : '40px',
        position: 'absolute',
        bottom: cart ? '560px' : '80px',
        right: '0',
        zIndex: '999'
      }}
      onClick={scrollToTop}
      data-content='&#8963;'
    />
  )
}

export default ScrollButton
