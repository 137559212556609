import { FC } from 'react'

import { Controller } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import { IPropInput } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectedInput: FC<IPropInput> = ({ registerConfig, ...props }) => {
  return (
    <ConnectForm>
      {({ register, control }) => (
        <Controller
          control={control}
          rules={registerConfig || { required: false }}
          name={props.name}
          render={({ field: { onChange, onBlur, ref, value } }) => {
            return (
              <ReactInputMask
                {...props}
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
                value={value}
                mask={props.mask!}
              />
            )
          }}
        />
      )}
    </ConnectForm>
  )
}
