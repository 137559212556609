import {
  forcast_american_football_bg,
  forcast_badminton_bg,
  forcast_baseball_bg,
  forcast_basketball_bg,
  forcast_beach_soccer_bg,
  forcast_beach_volleyball_bg,
  forcast_boxing_bg,
  forcast_chess_bg,
  forcast_darts_bg,
  forcast_esports_bg,
  forcast_football_bg,
  forcast_futsal_bg,
  forcast_handball_bg,
  forcast_hockey_bg,
  forcast_horse_racing_bg,
  forcast_rugby_bg,
  forcast_snooker_bg,
  forcast_table_tennis_bg,
  forcast_tennis_bg,
  forcast_volleball_bg,
  forcast_water_polo_bg
} from '../assets/experts/forcast-background-img'

type SportParams = {
  bg: string
  color: string
}

export const useGetSportBGAndColor = (sportName: string) => {
  const sportHelper: Record<string, SportParams> = {
    Football: { bg: forcast_football_bg, color: '#4A6937' },
    'Ice Hockey': { bg: forcast_hockey_bg, color: '#435C69' },
    Basketball: { bg: forcast_basketball_bg, color: '#623A2D' },
    Tennis: { bg: forcast_tennis_bg, color: '#A56820' },
    Baseball: { bg: forcast_baseball_bg, color: '#805F3A' },
    Volleyball: { bg: forcast_volleball_bg, color: '#A66820' },
    Rugby: { bg: forcast_rugby_bg, color: '#4C5F49' },
    Handball: { bg: forcast_handball_bg, color: '#805F3A' },
    Boxing: { bg: forcast_boxing_bg, color: '#8F4D4F' },
    'Table Tennis': { bg: forcast_table_tennis_bg, color: '#456466' },
    Chess: { bg: forcast_chess_bg, color: '#4E434E' },
    'American Football': { bg: forcast_american_football_bg, color: '#4C5F49' },
    Futsal: { bg: forcast_futsal_bg, color: '#4A6937' },
    Badminton: { bg: forcast_badminton_bg, color: '#6B0000' },
    Darts: { bg: forcast_darts_bg, color: '#456263' },
    'Water Polo': { bg: forcast_water_polo_bg, color: '#535B6D' },
    'Beach Soccer': { bg: forcast_beach_soccer_bg, color: '#4A6937' },
    'Beach Volleyball': { bg: forcast_beach_volleyball_bg, color: '#A66820' },
    Snooker: { bg: forcast_snooker_bg, color: '#446263' },
    Esports: { bg: forcast_esports_bg, color: '#4E434E' },
    'Horse Racing': { bg: forcast_horse_racing_bg, color: '#623A2D' }
  }

  const getSportColor = (): string => {
    return sportHelper[sportName].color || '#4A6937'
  }

  const getSportBackground = () => {
    return sportHelper[sportName].bg || forcast_football_bg
  }

  return {
    sportHelper,
    getSportColor,
    getSportBackground
  }
}
