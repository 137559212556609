import { FC } from 'react'

import clsx from 'clsx'

import { useIsMobile } from '@/shared/hooks'

import { Calendar } from '../calendar'
import { ConnectCalendar } from '../form-connect'
import { IPropCalendar } from '../form.interface'

import styles from './CalendarInput.module.scss'

export const CalendarInput: FC<IPropCalendar> = ({
  connect,
  registerConfig,
  containerClassName,
  name,
  onChange,
  defaultValue,
  value,
  calendarRef,
  isMini,
  isHigh,
  errorText,
  noMargin,
  withoutIcon,
  displayNone,
  isScrollLocked,
  ...restProps
}) => {
  const CalendarComponent = connect ? ConnectCalendar : Calendar

  const { isMobile } = useIsMobile()

  return (
    <div
      className={clsx(
        styles.wrapper,
        errorText && styles.inputError,
        noMargin && styles.noMargin,
        isMini && (isMobile ? styles.miniMobile : styles.mini),
        isHigh && styles.isHigh,
        displayNone && styles.displayNone,
        containerClassName
      )}>
      <CalendarComponent
        name={name}
        value={value}
        defaultValue={defaultValue}
        calendarRef={calendarRef}
        onChange={onChange}
        withoutIcon={withoutIcon}
        isScrollLocked={isScrollLocked}
        {...(connect
          ? {
              registerConfig
            }
          : {})}
        {...restProps}
      />
      {errorText && typeof errorText === 'string' && (
        <div className={styles.error}>
          <span>{errorText}</span>
        </div>
      )}
    </div>
  )
}
