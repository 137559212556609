import { Bounce, toast } from 'react-toastify'

import { getMessageError } from './getMessageError'

export const addMessageToast = (text: any, type: 'success' | 'error' | 'info' = 'error') => {
  const typeReverse = {
    success: 'error',
    error: 'success',
    info: 'info'
  } as Record<string, 'success' | 'error' | 'info'>

  toast[typeReverse[type]](getMessageError(text), {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce
  })
}
