import { ReactNode } from 'react'

import { StarFullGradientJSX, StarStrokeGradientJSX } from '../assets/common/svg'

export const getExpertStarRating = (rating: number) => {
  return (
    <div>
      {rating < 1 ? <StarStrokeGradientJSX /> : <StarFullGradientJSX />}
      {rating < 2 ? <StarStrokeGradientJSX /> : <StarFullGradientJSX />}
      {rating < 3 ? <StarStrokeGradientJSX /> : <StarFullGradientJSX />}
      {rating < 4 ? <StarStrokeGradientJSX /> : <StarFullGradientJSX />}
      {rating < 5 ? <StarStrokeGradientJSX /> : <StarFullGradientJSX />}
    </div>
  )
}
