import { useEffect, useState } from 'react'

/**
 * Возвращает поля для управления показом модального окна подтверждения
 * @param actions принимает ассинхронный экшен
 * */

export const useConfirmation = (actions: any) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmData, setConfirmData] = useState<any>(null)
  const [confirmationInfo, setConfirmationInfo] = useState<any>()
  const [confirmation, setConfirmation] = useState<boolean | null>()

  useEffect(() => {
    if (confirmation === true) {
      const func = actions(confirmationInfo)
      if (typeof func === 'function') func(confirmationInfo)
      setShowConfirmation(false)
      setConfirmation(null)
    } else if (confirmation === false) {
      setConfirmationInfo(undefined)
      setShowConfirmation(false)
      setConfirmation(null)
    }
  }, [confirmation])

  return {
    showConfirmation,
    setConfirmationInfo,
    setShowConfirmation,
    setConfirmation,
    confirmData,
    setConfirmData
  }
}
