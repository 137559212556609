import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { SocialBlock } from '@/entities/header/social-block'

import {
  InstagramJSX,
  QQJSX,
  TelegramJSX,
  TwitterJSX,
  WeChatJSX,
  WeChat_QRJSX,
  WeIboJSX
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'
import { Accordion } from '@/shared/ui/accordion'

import { footerLinks } from './constants'
import styles from './footer-new.module.scss'

export const Footer = () => {
  const { isMobile } = useIsMobile(1180)
  const { getFieldStatic } = useTranslationField()

  if (isMobile) {
    return (
      <div className={styles.wrapperMobile}>
        <div className={styles.linksList}>
          {footerLinks.map(item => {
            return (
              <Accordion
                isLink
                link={item.url}
                key={item.name}
                name={getFieldStatic(item.name as TValuesLocality)}
              />
            )
          })}
        </div>
        <p className={styles.text}>{getFieldStatic('footer_text')}</p>
        <div className={styles.social}>
          <SocialBlock isMobile />
        </div>
        <div className={styles.year}>
          {getFieldStatic('footer_label')} {new Date().getFullYear()}
        </div>
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.footerZone}>
        <div className={styles.infoBlock}>
          {footerLinks.map((item, index) => {
            return (
              <Fragment key={item.url}>
                <div className={styles.infoItem} key={item.url}>
                  <Link to={item.url}>{getFieldStatic(item.name as TValuesLocality)}</Link>{' '}
                </div>
                {index !== footerLinks.length - 1 && <span>|</span>}
              </Fragment>
            )
          })}
        </div>
        <p className={styles.text}>{getFieldStatic('footer_text')}</p>
        <SocialBlock />
        <div className={styles.year}>
          {getFieldStatic('footer_label')} {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}
