import { Location } from 'react-router-dom'

export const getPrevUrlLink = (data: string[][], location: Location) => {
  const pathnameCrumbs = location.pathname.split('/').filter(crumb => crumb !== '')

  const crumbs = pathnameCrumbs.concat(data.map(param => param[1])) || []

  const queryData = data.filter(([_, item]) => item !== crumbs.at(-1))
  const pathElements = location.pathname.split('/')

  const pathUrl = pathElements.reduce((acc, item, index) => {
    if (
      typeof Number(pathElements.at(-1)) === 'number' &&
      !isNaN(Number(pathElements.at(-1))) &&
      item === pathElements.at(-2)
    ) {
      return acc
    }
    const slash = index === 0 ? '' : '/'

    if (index !== pathElements.length - 1) return acc + slash + item
    return acc ? acc : '/'
  }, '')

  if (!queryData.length && !data.length) {
    return pathUrl
  }
  const stringPath = queryData.reduce((acc, item) => acc + item[0] + '=' + item[1] + '&', '')
  return location.pathname + '?' + stringPath
}
