import React, { FC, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './quiz.module.scss'

interface IProps extends PropsWithChildren {
  background: string
  extraImage?: string
  isFinal?: boolean
}

export const QuizLayout: FC<IProps> = ({ background, children, isFinal, extraImage }) => {
  return (
    <div className='quiz'>
      {extraImage && (
        <div
          className={clsx(styles.extraImage, !isFinal && styles.hidden)}
          style={{
            backgroundImage: `url(${extraImage})`
          }}
        />
      )}
      <div
        className={styles.wrapper}
        style={{
          backgroundImage: `url(${background})`
        }}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}
