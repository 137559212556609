import { useEffect, useLayoutEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

/*
 * @return {object} - Объект, содержащий query-параметры из URL.
 */
type QueryParamsType = {
  [key: string]: string
}

export const useQueryParams = () => {
  const { search } = useLocation()

  const [queryParams, setQueryParams] = useState<QueryParamsType>({})

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(search)
    const params: QueryParamsType = Object.fromEntries(urlParams.entries())

    setQueryParams(params)
  }, [search])

  return queryParams
}
