import { FC } from 'react'

import { QuizLayout } from '@/features/quiz/ui/layout'

import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutTwo } from '@/shared/ui/quiz-layout/quiz-layout-2'

import {
  backgroundQuiz_33,
  image_3,
  image_3_mobi,
  image_32,
  image_32_mobi
} from '@/Assets/img/quiz'

import { TQuiz } from '../..'

export const QuizThree: FC<TQuiz> = ({ onNextStep }) => {
  const { isMobile } = useIsMobile(768)
  const { getFieldStatic } = useTranslationField()

  return (
    <QuizLayout background={backgroundQuiz_33}>
      <QuizLayoutTwo
        title={getFieldStatic('questionThreeVariant2')}
        image={isMobile ? image_32_mobi : image_32}
        onNextStep={onNextStep}
      />
    </QuizLayout>
  )
}
