import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { TStepAuth } from '@/app/providers/protected-router'

import {
  ForgotPassword,
  ForgotPasswordCode,
  FourDigitCode,
  HaveCode,
  Login,
  Registration
} from '@/features/auth/ui'
import { LangChanger } from '@/features/header/ui/lang-changer'

import cn_background from '@/shared/assets/auth/img/cn_baground.webp'
import en_background from '@/shared/assets/auth/img/en_baground.webp'
import { FINAL_QUIZ } from '@/shared/constants'
import {
  useIsMobile,
  useLocalStorage,
  useLockedScroll,
  useQueryParams,
  useTranslationField
} from '@/shared/hooks'

import './Auth.scss'

type IProps = {
  widget?: boolean
  onForgotNext?: (val: { email: string; values: string[] }) => void
  setPageName: Dispatch<SetStateAction<TStepAuth>> | Dispatch<SetStateAction<string>>
  pageName: TStepAuth | string
}

export const Auth = ({ widget, onForgotNext, pageName, setPageName }: IProps) => {
  const { localValue } = useLocalStorage(FINAL_QUIZ)
  const { locale } = useTranslationField()
  const { isMobile } = useIsMobile()
  const backgroundLocale = locale === 'en' ? en_background : cn_background
  const params = useQueryParams()
  useLockedScroll(true)

  const [dataAuthForm, setDataAuthForm] = useState<{
    [x: string]: string
  }>({})

  useEffect(() => {
    if (!params?.ref) return
    setPageName('registration')
  }, [params?.ref])

  const getAuthStepComponent = () => {
    switch (pageName) {
      case 'registration':
        return (
          <Registration
            refCode={params?.ref}
            page={setPageName}
            setDataAuthForm={setDataAuthForm}
            widget={widget}
          />
        )
      case 'have-code':
        return <HaveCode page={setPageName} widget={widget} />
      case 'forgot-password':
        return <ForgotPassword page={setPageName} setEmail={setDataAuthForm} widget={widget} />
      case 'forgot-password-code':
        return (
          <ForgotPasswordCode
            page={setPageName}
            email={dataAuthForm?.email}
            localValue={localValue}
            widget={widget}
          />
        )
      case 'four-digit-code':
        return (
          <FourDigitCode
            page={setPageName}
            authDataForm={dataAuthForm}
            onForgotNext={onForgotNext}
            localValue={localValue}
            widget={widget}
          />
        )
      case 'login':
        return <Login page={setPageName} widget={widget} />
      default:
        return <></>
    }
  }

  return (
    <div className='FormBlock' style={{ backgroundImage: backgroundLocale }}>
      {isMobile && (
        <div className='langChanger'>
          <LangChanger isMobile />
        </div>
      )}

      {getAuthStepComponent()}
    </div>
  )
}
