import { FC, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'
import { useAuthStore } from '@/app/zustand-store'

import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LogoChiJSX,
  LogoEnJSX
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { useTimerBack } from '@/shared/hooks/useTimerBack'
import { IUsers } from '@/shared/types/user.types'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

import { useAuthActions } from '../api'

import { FourConfirmCode } from './four-confirm-code'

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
  onForgotNext?: (val: { email: string; values: string[] }) => void
  authDataForm: any
  localValue: string | null
}

export const FourDigitCode: FC<IProps> = ({
  page,
  widget = false,
  authDataForm,
  onForgotNext,
  localValue
}) => {
  const navigate = useNavigate()
  const { getFieldStatic, locale } = useTranslationField()
  const { register, activation } = useAuthActions()
  const { setTimerStart, timer, timerStart } = useTimerBack(60)
  const { email } = authDataForm
  const { isMobile } = useIsMobile()
  const [values] = useState<string[]>(['', '', '', ''])
  const { changeAuthorized } = useAuthStore()
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setTimerStart(true)
  }, [])

  const submitActivate = async (values: string[]) => {
    const data = {
      email,
      code: values.join('')
    }

    if (onForgotNext) {
      onForgotNext?.({ values, email })
    } else {
      try {
        await activation.mutateAsync(data as IUsers.IEmailCode)
        changeAuthorized(true)

        setIsError(false)
        page('')
        if (localValue && !isError) {
          navigate('/profile?tab=account-profile')
        } else {
          navigate('/')
        }
      } catch (error: any) {
        setIsError(true)
        addMessageToast(error)
      }
    }
  }

  const isDisabledSubmit = values.some(v => !v.trim()) || register.isLoading

  const onSendCode = async () => {
    try {
      const dataForm = authDataForm
      await register.mutateAsync(dataForm as IUsers.IRegister)
      page('four-digit-code')
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  if (activation.isLoading || register.isLoading) return <Loader />

  return (
    <div className='LoginForm' style={{ textAlign: 'center' }}>
      {locale === 'en' ? <LogoEnJSX className='logo' /> : <LogoChiJSX className='logo' />}
      <div className='formGroup' style={{ maxWidth: '400px' }}>
        <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>
        <FourConfirmCode
          email={email}
          onFinalEnter={submitActivate}
          isLoading={register.isLoading}
          isError={isError}
          title={isMobile ? getFieldStatic('verify') : undefined}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            fontWeight: '550',
            fontSize: '14px',
            textTransform: 'none',
            marginTop: '10px'
          }}>
          <div>{getFieldStatic('repeatCode')}</div>

          {timerStart ? (
            <div>
              {timerStart ? `${timer} ` : ''}
              {timerStart && getFieldStatic('second')}
            </div>
          ) : (
            <div
              className='link'
              style={{
                fontWeight: '550'
              }}
              onClick={() => {
                onSendCode()
                setTimerStart(true)
              }}>
              {getFieldStatic('send')}
            </div>
          )}
        </div>
        {/* <span
          style={{
            cursor: 'default',
            textAlign: 'center'
          }}>
          {getFieldStatic('message_code')}
        </span> */}
        <small></small>
      </div>
    </div>
  )
}

export default FourDigitCode
