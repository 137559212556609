// Библиотечные компоненты
import { FC } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'

import { useProfileData } from '@/features/profile/api/useProfileData'

import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LoginIconCn,
  LoginIconEn
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { validEmail } from '@/shared/regex'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

import { useAuthActions } from '../api'

// Компоненты

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
}
export const HaveCode: FC<IProps> = ({ page, widget = false }) => {
  const { activation } = useAuthActions()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  const { refetch } = useProfileData()
  const methods = useForm({
    mode: 'onBlur'
  })

  const {
    formState: { errors }
  } = methods

  const handlerFormData = async (values: FieldValues) => {
    const payload = {
      email: values.email,
      code: values.code.replaceAll(' ', '')
    }

    try {
      await activation.mutateAsync(payload as IUsers.IEmailCode)
      refetch()
      page('')
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  if (activation.isLoading) return <Loader />

  return (
    <FormWrapper methods={methods} onSubmit={handlerFormData} className='LoginForm'>
      {locale === 'en' ? <LoginIconEn className='logo' /> : <LoginIconCn className='logo' />}
      <div className='formGroup'>
        <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>
        <div className='title'>{getFieldStatic('verify')}</div>
        <div className='auth-block-wrap'>
          <Input
            mask=''
            name='email'
            type='email'
            connect
            placeholder={getFieldStatic('login_email_code')}
            errorText={errors?.email?.message}
            registerConfig={{
              required: getFieldStatic('required_field'),
              pattern: {
                value: validEmail,
                message: getFieldStatic('correct_email')
              }
            }}
          />
          <div>
            <Input
              mask='9 9 9 9'
              name='code'
              type='code'
              connect
              placeholder={getFieldStatic('login_code')}
              errorText={errors?.code?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                minLength: {
                  message: getFieldStatic('correct_length'),
                  value: 4
                },
                validate: value => {
                  if (!(value as any)?.includes('_')) return true
                  return getFieldStatic('correct_length')
                }
              }}
            />
          </div>
          <Button modifiers={['red']} classNameContainer='buttonContainer'>
            {getFieldStatic('confirm')}
          </Button>
        </div>
        <span style={{ cursor: 'default' }} className='info infoText'>
          {getFieldStatic('message_code')}
        </span>
      </div>
    </FormWrapper>
  )
}
