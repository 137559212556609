import { FC } from 'react'

import clsx from 'clsx'

import { ConnectedRadioGroup } from '../form-connect'
import { IPropsRadioGroup } from '../form.interface'
import { RadioButton } from '../radio-button'

import styles from './RadioGroup.module.scss'

export const RadioGroupDefault: FC<IPropsRadioGroup> = ({
  options,
  onChange,
  classNameWrapper,
  ...props
}) => {
  return (
    <div>
      {options.map(item => (
        <RadioButton
          {...props}
          key={item.value}
          name={props.name}
          onChange={onChange}
          title={item.label}
          value={item.value}
          classNameWrapper={classNameWrapper}
        />
      ))}
    </div>
  )
}

export const RadioGroup: FC<IPropsRadioGroup> = ({
  connect,
  name,
  containerClassName,
  options,
  registerConfig,
  value,
  classNameWrapper,
  onChange,
  onBlur,
  ...props
}) => {
  const CalendarComponent = connect ? ConnectedRadioGroup : RadioGroupDefault

  return (
    <div className={clsx(styles.wrapper, containerClassName)}>
      <CalendarComponent
        name={name}
        options={options}
        connect={connect}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...(connect
          ? {
              registerConfig
            }
          : {})}
        {...props}
      />
    </div>
  )
}
