import React, { FC, useState } from 'react'

import clsx from 'clsx'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { TbWorld } from 'react-icons/tb'

import { setLang } from '@/app/store/actions/defaultActions'
import { languages } from '@/app/store/constants'

import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import styles from './LangChange.module.scss'

interface IProps {
  isMobile?: boolean
}
export const LangChanger: FC<IProps> = ({ isMobile }) => {
  const { locale } = useAppSelector(state => state.defaultReducer)
  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const { ref } = useOutsideClick(false, () => setIsActiveMenu(false))
  const dispatch = useAppDispatch()

  if (isMobile) {
    return (
      <div
        ref={ref}
        className={styles.wrapperMobile}
        onClick={() => {
          setIsActiveMenu(prev => !prev)
        }}>
        <div className={clsx(styles.blockMobile, isActiveMenu && styles.subMenuActive)}>
          <div className={styles.world}>{languages.find(item => locale === item.code)?.name}</div>
        </div>

        <div className={clsx(styles.subMenu, isActiveMenu && styles.active)}>
          {languages.map((item, index) => (
            <div
              className={clsx(styles.element, item.code === locale && styles.disabled)}
              key={index}
              onClick={() => {
                dispatch(setLang(item.code))

                setIsActiveMenu(false)
              }}>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div
      ref={ref}
      className={styles.wrapper}
      onClick={() => {
        setIsActiveMenu(prev => !prev)
      }}>
      <span className={clsx(styles.block, isActiveMenu && styles.subMenuActive)}>
        <div className={styles.world}>
          {languages.find(item => locale === item.code)?.name}
          {/* <TbWorld /> */}
        </div>
      </span>
      <div className={styles.icon}>{isActiveMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>

      <div className={clsx(styles.subMenu, isActiveMenu && styles.active)}>
        {languages.map((item, index) => (
          <div
            className={clsx(styles.element, item.code === locale && styles.disabled)}
            key={index}
            onClick={() => {
              dispatch(setLang(item.code))
              setIsActiveMenu(false)
            }}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  )
}
