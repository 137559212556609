import React, { FC } from 'react'

import { Tooltip } from 'react-tooltip'

import {
  InstagramJSX,
  QQJSX,
  TelegramJSX,
  TwitterJSX,
  WeChatJSX,
  WeChat_QRJSX,
  WeIboJSX
} from '@/shared/assets/common/svg'

import styles from './SocialBlock.module.scss'

interface IProps {
  isMobile?: boolean
}
export const SocialBlock: FC<IProps> = ({ isMobile }) => {
  return (
    <div>
      <p className={styles.social}>
        <a href='https://weibo.com/u/5020382965'>
          <WeIboJSX />
        </a>
        {isMobile ? (
          <a href='https://u.wechat.com/kKEivAPXAlkVtgXTNdfVuTs'>
            <WeChatJSX />
          </a>
        ) : (
          <>
            <Tooltip className={styles.tooltipWrapp} id='my-tooltip-inline'>
              <div className={styles.tooltip}>
                <WeChat_QRJSX />
              </div>
            </Tooltip>
            <span data-tooltip-id='my-tooltip-inline'>
              <WeChatJSX />
            </span>
          </>
        )}

        <a href='https://qm.qq.com/q/typ1OeroS6'>
          <QQJSX />
        </a>
        <a href='https://t.me/lebaoba'>
          <TelegramJSX />
        </a>
        <a href=' https://www.instagram.com/lebao.ba?igsh=NWRma3ppYzc0bmxs&utm_source=qr'>
          <InstagramJSX />
        </a>
        <a href='https://x.com/lebaofun?s=21'>
          <TwitterJSX />
        </a>
      </p>
    </div>
  )
}
