import { ChangeEvent, FC } from 'react'

import cn from 'clsx'

import { ReactComponent as MinusIcon } from '@/Assets/svg/minus-40x40.svg'
import { ReactComponent as PlusIcon } from '@/Assets/svg/plus-40x40.svg'

import styles from './InputCounter.module.scss'
import { IInputCounter } from './input-counter.interface'

const InputCounter: FC<IInputCounter> = ({ value, setValue, className }) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value !== 0 && !+e.target.value) return
    setValue(+e.target.value)
    setTimeout(() => window.dispatchEvent(new Event('stake')), 200)
  }

  const onClickMinusButton = () => {
    setValue(prevState => (prevState > 100 ? prevState - 100 : 0))
    setTimeout(() => window.dispatchEvent(new Event('stake')), 200)
  }
  const onClickPlusButton = () => {
    setValue(prevState => prevState + 100)
    setTimeout(() => window.dispatchEvent(new Event('stake')), 200)
  }

  return (
    <div className={cn(styles.inputCounter, className)}>
      <button type='button' onClick={onClickMinusButton}>
        <MinusIcon />
      </button>
      <input name='stake' type='text' value={value} onChange={onChange} />
      <button type='button' onClick={onClickPlusButton}>
        <PlusIcon />
      </button>
    </div>
  )
}

export default InputCounter
