// Библиотечные компоненты
import { Dispatch, FC, useState } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'

// Изображения
import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LogoChiJSX,
  LogoEnJSX
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
// Компоненеты
// Экшны
import { Button, Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

import { useAuthActions } from '../../api'

import styles from './Register.module.scss'

interface IProps {
  page: (page: TStepAuth) => void
  setDataAuthForm: Dispatch<React.SetStateAction<any>>
  widget?: boolean
  refCode?: string
}

export const Registration: FC<IProps> = ({ page, refCode, widget = false, setDataAuthForm }) => {
  const { register } = useAuthActions()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  const form = useForm({ mode: 'onBlur' })
  const {
    formState: { errors }
  } = form
  const [checked, setChecked] = useState(true)

  const onSendCode = async (data?: FieldValues) => {
    const dataForm = data || form.getValues()
    setDataAuthForm(dataForm)

    try {
      const dataForm = Object.assign(data || form.getValues(), { ref: refCode })
      await register.mutateAsync(dataForm as IUsers.IRegister)
      page('four-digit-code')
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  const onRegistration = (data: FieldValues) => {
    onSendCode(data)
  }

  const onCloseBlock = () => {
    if (widget) return page('')
    if (refCode) return navigate('/')
    navigate(-1)
  }

  const disabledButton = !checked || register.isLoading

  return !register.isLoading ? (
    <FormWrapper onSubmit={onRegistration} methods={form} className='RegisterForm'>
      {locale === 'en' ? <LogoEnJSX className='logo' /> : <LogoChiJSX className='logo' />}
      <div className='formGroup'>
        <div className='close' onClick={onCloseBlock}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>

        <div className='title'>{getFieldStatic('title_register')}</div>
        <div className='auth-block-wrap'>
          <div>
            <Input
              mask=''
              name='email'
              type='email'
              connect
              placeholder={getFieldStatic('login_email')}
              errorText={errors?.email?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: getFieldStatic('correct_email')
                }
              }}
            />
          </div>
          <div>
            <Input
              mask=''
              name='name'
              type='name'
              connect
              placeholder={getFieldStatic('username')}
              // style={{ marginTop: '-5px' }}
              errorText={errors?.name?.message}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
          </div>
          <div className='inputPass'>
            <Input
              mask=''
              name='password'
              type='password'
              connect
              isPassword
              placeholder={getFieldStatic('login_pass')}
              errorText={errors?.password?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                minLength: {
                  value: 8,
                  message: getFieldStatic('minimum_characters') + ' - 8'
                }
              }}
            />
          </div>

          <Checkbox
            className={styles.checkbox}
            checkboxClassName={styles.checkbox__check}
            title={getFieldStatic('accept_terms_conditions')}
            checked={checked}
            modifier={['center']}
            onChange={() => {
              setChecked(!checked)
            }}
          />
          <Button
            disabled={disabledButton}
            modifiers={['red']}
            classNameContainer='buttonContainer'>
            {getFieldStatic('register')}
          </Button>
        </div>
        <div className={styles.actionStep}>
          {getFieldStatic('have_account')}
          <span className='link' onClick={() => page('login')}>
            {getFieldStatic('log_in')}
          </span>
        </div>
        <div className={styles.actionStep}>
          {getFieldStatic('have_code')}
          <span className='link' onClick={() => page('have-code')}>
            {getFieldStatic('verify')}
          </span>
        </div>
      </div>
    </FormWrapper>
  ) : (
    <Loader />
  )
}

export default Registration
