import React, { useEffect } from 'react'

export const useDisabledScroll = (isDisabled?: boolean, scrollToInit?: boolean) => {
  useEffect(() => {
    if (isDisabled) {
      document.body.style.overflow = 'hidden'
      scrollToInit && window.scrollTo(0, 0)
    }
    return () => {
      document.body.style.overflow = 'auto'
      // scrollToInit && window.scrollTo(0, 0)
    }
  }, [isDisabled])
}
