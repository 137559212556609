import { useEffect } from 'react'

import { useLocalStorage, useQueryParams } from '@/shared/hooks'

export const KABAM_POSTBACK = 'kabam.postback.info'

export const useForwardPostBack = () => {
  const queryParams = useQueryParams()
  const { setLocalStorageValue, deleteLocalStorageValue } = useLocalStorage(KABAM_POSTBACK)

  useEffect(() => {
    if (queryParams?.subid) {
      setLocalStorageValue(JSON.stringify(queryParams))
    }
  }, [queryParams])

  return deleteLocalStorageValue
}
