import { useEffect, useRef, useState } from 'react'

type PopupClickOutside = MouseEvent & {
  target: Node
}

/*
 * @return {object} - Объект, содержащий:
 *   - menuRef: Ссылка на DOM-элемент, для которого отслеживается клик вне.
 *   - isActive: Текущее состояние активности элемента (true - активен, false - не активен).
 *   - setIsActive: Функция для обновления состояния активности элемента.
 */
const useClickOutside = () => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = useState<boolean>(false)

  const handleClickOutside = (event: MouseEvent) => {
    const _event = event as PopupClickOutside

    if (menuRef.current && !menuRef.current?.contains(_event.target)) {
      setTimeout(() => setIsActive(false), 0) // Задержка для корректной работы в некоторых браузерах
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutside, true)

    return () => document.body.removeEventListener('click', handleClickOutside, true)
  }, [])

  return { menuRef, isActive, setIsActive }
}

export default useClickOutside
