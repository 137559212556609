export class LocalStorageHelper {
  private static currentLang: string = localStorage.getItem('locale') || 'en'

  constructor() {
    // Подписываемся на изменения в localStorage
    window.addEventListener('storage', this.syncLocalStorage)
  }

  // Функция для синхронизации значения
  private syncLocalStorage = () => {
    LocalStorageHelper.currentLang = localStorage.getItem('locale') || 'en'
  }

  // Функция для ручного обновления языка
  public static setLang(newLang: string) {
    localStorage.setItem('locale', newLang)
    LocalStorageHelper.currentLang = newLang // Обновляем текущее значение
  }

  // Функция для получения актуального значения
  public static getLang(): string {
    return LocalStorageHelper.currentLang
  }

  // Отписка от события, если это необходимо
  public dispose() {
    window.removeEventListener('storage', this.syncLocalStorage)
  }
}
