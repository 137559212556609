import { useEffect, useRef } from 'react'

/**
 * Возвращает ref, который прикрепляется к iframe для корректной отображения игры в Iframe
 * */
export function useHandlerIframe() {
  const refFrame = useRef<HTMLIFrameElement>(null)

  const handlerMessageIframe = (event: MessageEvent<any>) => {
    const { channel } = event.data
    const iframeElement = refFrame.current

    if (channel === 'GPWebAppBridge' && iframeElement) {
      const { type, data } = event.data
      if (type === 'GPWebAppInit') {
        //@ts-ignore
        iframeElement.contentWindow.postMessage(
          {
            type: 'SetSupportedMethods',
            data: []
          },
          '*'
        )
        //@ts-ignore
        iframeElement.contentWindow.postMessage(
          {
            type: 'GPWebAppInitResult',
            data: {
              result: true,
              requestId: data.requestId
            }
          },
          '*'
        )
      }
    }
  }

  useEffect(() => {
    if (!refFrame.current) return

    window.addEventListener('message', handlerMessageIframe)
    return () => {
      window.removeEventListener('message', handlerMessageIframe)
    }
  }, [refFrame])

  return { refFrame }
}
