import { axiosInstance } from '@/shared/api'
import { getSportUrl } from '@/shared/api/config/api.config'
import { IPaginationData, IPaginationDataWithDate } from '@/shared/types/global.types'
import { ISport } from '@/shared/types/sport.types'

const SportService = {
  async getSports({ page = 1, count = 21 }: IPaginationData) {
    return axiosInstance.get<ISport.ISport[]>(
      getSportUrl(`/list/sports?page=${page}&count=${count}`)
    )
  },

  async getBets({
    page = 1,
    count = 21,
    fromDate = new Date('2023-01-01T00:00:01'),
    toDate = new Date()
  }: IPaginationDataWithDate) {
    return axiosInstance.get<ISport.IBet[]>(
      getSportUrl(`/bet/getAll?page=${page}&count=${count}&fromDate=${fromDate}&toDate=${toDate}`)
    )
  },

  async getTournaments({ page, count, sportId }: ISport.IGetAllTournamentsData) {
    return axiosInstance.get<ISport.ITournament[]>(
      getSportUrl(
        sportId
          ? `/list/tournaments?page=${page}&count=${count}&sportId=${sportId}`
          : `/list/tournaments?page=${page}&count=${count}`
      )
    )
  },

  async getTournamentById(id: number) {
    return axiosInstance.get<ISport.ITournamentById>(getSportUrl(`/get/tournament/${id}`))
  },

  async getTournamentEventList({
    tournamentId,
    page = 1,
    count = 21,
    fromDate = new Date('2023-01-01T00:00:01').toISOString(),
    toDate = new Date().toISOString()
  }: ISport.IGetTournamentEventListData) {
    return axiosInstance.get<ISport.IGame[]>(
      getSportUrl(
        `/list/games?tournament=${tournamentId}&page=${page}&count=${count}&fromDate=${fromDate}&toDate=${toDate}`
      )
    )
  },

  async getGameInfo(id: number) {
    return axiosInstance.get<ISport.IGame>(getSportUrl(`/get/game/${id}`))
  },

  async getSearchedMatch(searchTerm: string) {
    return axiosInstance.get<Omit<ISport.IGame, 'markets'>[]>(
      getSportUrl(`/search/match?query=${searchTerm}`)
    )
  },

  async getMarketsByGameId(id: number) {
    return axiosInstance.get<ISport.IMarket[]>(getSportUrl(`/get/market/${id}`))
  },

  async betOdinar(id: string, amount: FormData) {
    return axiosInstance.post<ISport.IBet>(getSportUrl(`/bet/odinar?marketId=${id}`), amount)
  },
  async betExpress(markets: string[], express_bet_amount: number) {
    const marketsString = markets.reduce((acc, item, i) => {
      return !i ? `${acc}markets=${item}` : `${acc}&markets=${item}&`
    }, '?')

    const form = new FormData()
    form.append('express_bet_amount', express_bet_amount.toString())

    return axiosInstance.post<ISport.IBet>(getSportUrl(`/bet/express${marketsString}`), form)
  }
}

export default SportService
