import { forwardRef } from 'react'

import { clsx } from 'clsx'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import ReactInputMask from 'react-input-mask'

import { CalendarIconJSX } from '@/shared/assets/common/svg'

import styles from './CustomInput.module.scss'
import { ICustomInput } from './calendar.interface'

const CustomInput = forwardRef<HTMLInputElement, ICustomInput>(
  (
    { onClick, onClickHandler, onChange, className, placeholderText, withoutIcon, ...rest },
    ref
  ) => {
    return (
      <label
        className={clsx(styles.input, className)}
        onClick={() => {
          onClickHandler()
        }}>
        <ReactInputMask
          autoComplete='off'
          onChange={onChange}
          placeholder={'placeholderText'}
          mask={'99/99/9999'}
          {...rest}
        />
        {!withoutIcon && <CalendarIconJSX />}
      </label>
    )
  }
)

export default CustomInput
