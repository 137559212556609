import React, { useEffect, useState } from 'react'

/*
 * @return {object} - Объект, содержащий:
 *   - documentHeight:  Полная высота документа минус высота окна.
 *   - scrollPositionPercent:  Текущий процент прокрутки документа.
 *   - scrollPositionTop:  Вертикальная позиция прокрутки от начала документа.
 */
export const useScrollHook = () => {
  const [documentHeight, setDocumentHeight] = useState(0)
  const [scrollPositionPercent, setScrollPositionPercent] = useState(0)
  const [scrollPositionTop, setScrollPositionTop] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY // Текущая позиция прокрутки
      const docHeight = document.documentElement.scrollHeight - window.innerHeight // Полная высота документа минус высота окна
      const scrollPercent = (scrollTop / docHeight) * 100 // Вычисление процента прокрутки
      setScrollPositionPercent(scrollPercent) // Обновление состояния
      setDocumentHeight(docHeight)
      setScrollPositionTop(scrollTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { documentHeight, scrollPositionPercent, scrollPositionTop }
}
