import { FC } from 'react'

import { clsx } from 'clsx'

import styles from './Slider.module.sass'
import { ISlider } from './slider.interface'

const Slider: FC<ISlider> = ({ id, className, ...rest }) => {
  return (
    <label htmlFor={id} className={clsx(styles.slider, className)}>
      <input id={id} type='checkbox' className={styles.realCheckbox} {...rest} />
      <span className={styles.sliderMark} />
    </label>
  )
}

export default Slider
