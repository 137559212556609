import { Dispatch } from 'redux'

import { LocalStorageHelper } from '@/shared/helper'

export const setLang = (lang: string) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_LOCALE',
    value: lang
  })

  LocalStorageHelper.setLang(lang)
  window.dispatchEvent(new Event('storage'))
  // @ts-ignore
  document.getElementById('html').setAttribute('lang', lang)
}
