import React, { FC } from 'react'

import clsx from 'clsx'
import { PinInput } from 'react-input-pin-code'

import styles from './PinDigit.module.scss'

interface IProps {
  label?: string
  values: string[]
  onChange: (value: string[]) => void
  isSuccess?: boolean
  isError?: boolean
  isDisabled?: boolean
  placeholder?: string
}
export const PinDigit: FC<IProps> = ({
  label,
  values,
  onChange,
  isError,
  isSuccess,
  isDisabled,
  placeholder
}) => {
  const fieldFull = values.filter(item => item !== '')
  return (
    <div>
      <div className={styles.pinWrapper}>
        {label && <div className={styles.pin}> {label}</div>}
        <PinInput
          placeholder={placeholder}
          values={values}
          disabled={isDisabled}
          onChange={(value, index, values) => onChange(values)}
          containerClassName={clsx(styles.pinInputWrapper, styles[`inputs${fieldFull.length}`])}
          inputClassName={clsx(
            styles.pinInput,
            isSuccess && styles.success,
            isError && styles.error
          )}
          validBorderColor='#D5070E'
        />
      </div>
    </div>
  )
}
