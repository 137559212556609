import axios from 'axios'

interface IProps {
  subid: string
  [x: string]: string
}
const PostBackService = {
  async sendPostBackKabam({ subid, ...rest }: IProps) {
    return axios.get<any>('https://kdtrk.net/ru/postback', {
      params: {
        data: subid,
        ...rest
      }
    })
  }
}

export default PostBackService
