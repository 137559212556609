import { FC, useState } from 'react'

import cn from 'clsx'

import { BasketBets } from '@/widgets/sports/bets-basket/basket.interface'
import { useBasket } from '@/widgets/sports/bets-basket/useBasket'

import { useTranslationField } from '@/shared/hooks'
import { useAppSelector } from '@/shared/model'
import InputCounter from '@/shared/sports/input-counter/InputCounter'

import { ReactComponent as CloseIcon } from '@/Assets/svg/x-mark-sm.svg'

import styles from './SingleBet.module.scss'

const SingleBet: FC<BasketBets.Basket> = ({
  main_const_sport_event_id,
  opponent_one_image,
  opponent_two_image,
  start_date,
  opponent_one_name_en,
  opponent_one_name_cn,
  opponent_two_name_en,
  opponent_two_name_cn,
  market,
  tournament,
  singl = true
}) => {
  const { getFieldStatic } = useTranslationField()
  const [inputValue, setInputValue] = useState(0)
  const { locale } = useAppSelector(state => state.defaultReducer)
  const cart = useBasket()

  const removeItemCart = () => {
    const newCart = cart.filter(
      item => item.main_const_sport_event_id !== main_const_sport_event_id
    )
    localStorage.setItem('cart', JSON.stringify(newCart))
    window.dispatchEvent(new Event('storage'))
  }

  return (
    <div
      className={cn(styles.singleBet, {
        [styles.blocked]: market.is_blocked
      })}>
      <div className={styles.matchName}>
        <span>{locale === 'en' ? tournament.name_en : tournament.name_cn}</span>
      </div>
      <div className={styles.teamInfo}>
        <div>
          <span>{locale === 'en' ? opponent_one_name_en : opponent_one_name_cn}</span>
        </div>
        <div>
          <span>{locale === 'en' ? opponent_two_name_en : opponent_two_name_cn}</span>
        </div>
      </div>
      <div className={styles.totalInfo}>
        <p>
          <span>{locale === 'en' ? market.name_en : market.name_cn}</span>
        </p>
        <span>{market.market}</span>
      </div>
      {singl && (
        <>
          <div className={styles.totalInfo}>
            <p>
              <span>{getFieldStatic('potential_winnings')}</span>
            </p>
            <span>{(market.market * inputValue).toFixed(2)}</span>
          </div>
          <InputCounter
            value={inputValue}
            setValue={setInputValue}
            className={styles.inputCounter}
          />
        </>
      )}
      <button
        type='button'
        className={cn(styles.close, {
          [styles.fillWhite]: market.is_blocked
        })}
        onClick={() => removeItemCart()}>
        <CloseIcon />
      </button>

      {market.is_blocked && (
        <div className={styles.blockedOverlay}>
          <span>{getFieldStatic('blocked')}</span>
        </div>
      )}
    </div>
  )
}

export default SingleBet
