import { FC } from 'react'

import { clsx } from 'clsx'

import { useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'

import styles from './RadioBlock.module.sass'
import { IRadioBlock } from './radio-block.interface'

const RadioBlock: FC<IRadioBlock> = ({ id, text, className, ...rest }) => {
  const { getFieldStatic } = useTranslationField()

  return (
    <label htmlFor={id} className={clsx(styles.radioBlock, className)}>
      <p>{getFieldStatic(text as TValuesLocality)}</p>
      <div className={styles.radio}>
        <input type='radio' id={id} className={styles.realRadio} {...rest} />
        <section className={styles.customRadio}>
          <div className={styles.radioMark} />
        </section>
      </div>
    </label>
  )
}

export default RadioBlock
