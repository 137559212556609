import { FC } from 'react'

import { QuizLayout } from '@/features/quiz/ui/layout'

import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutOne } from '@/shared/ui/quiz-layout/quiz-layout-1'

import { backgroundQuiz_3, image_3, image_3_mobi } from '@/Assets/img/quiz'

import { TQuiz } from '../..'

export const QuizThree: FC<TQuiz> = ({ onNextStep }) => {
  const { isMobile } = useIsMobile(768)
  const { getFieldStatic } = useTranslationField()

  return (
    <QuizLayout background={backgroundQuiz_3}>
      <QuizLayoutOne
        image={isMobile ? image_3_mobi : image_3}
        onNextStep={onNextStep}
        title={getFieldStatic('questionThreeVarian1')}
      />
    </QuizLayout>
  )
}
