import { FC, PropsWithChildren } from 'react'

import { Provider as AlertProvider, positions, transitions } from 'react-alert'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { store } from '@/app/store/store'

import { AlertTemplate } from '@/shared/ui/alert-template'

import { ErrorInterceptor } from './ErrorInterceptor'
import { QueryClientProvider } from './QueryClientProvider'
import { LOCALES } from '@/i18n/locales'
import { messages } from '@/i18n/messages'

const optionsAlert = {
  position: positions.BOTTOM_RIGHT,
  timeout: 4000, // 5000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: { zIndex: 9999 }
}

const MainProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = store.getState().defaultReducer

  return (
    <Provider store={store}>
      <QueryClientProvider>
        <IntlProvider
          //@ts-ignore
          messages={messages[locale]}
          locale={locale}
          defaultLocale={LOCALES.CHINESE}>
          <ToastContainer style={{ zIndex: 99999 }} />
          <AlertProvider template={AlertTemplate} {...optionsAlert}>
            <ErrorInterceptor>{children}</ErrorInterceptor>
          </AlertProvider>
        </IntlProvider>
      </QueryClientProvider>
    </Provider>
  )
}

export default MainProvider
