import { create } from 'zustand'

interface CommonState {
  emailPulse: boolean
  changeEmailPulse: (isPulse: boolean) => void
}

export const useCommonStore = create<CommonState>(set => ({
  emailPulse: false,
  changeEmailPulse: (isPulse: boolean) => set(state => ({ emailPulse: isPulse })) // Обновлено на правильное имя свойства
}))
