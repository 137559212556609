import { FC, useEffect, useRef } from 'react'

import { Controller } from 'react-hook-form'

import { IPropsRadioGroup } from '../form.interface'
import { RadioButton } from '../radio-button'

import { ConnectForm } from './ConnectForm'

export const ConnectedRadioGroup: FC<IPropsRadioGroup> = ({
  registerConfig,
  options,
  classNameWrapper,
  isMobileSelector,
  additionalAction,
  onChange,
  type,
  scroll,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!containerRef.current) return
    setTimeout(() => {
      const selectedButton =
        containerRef.current &&
        containerRef.current.querySelector(`input[name="${props.name}"]:checked`)

      if (scroll && selectedButton) {
        selectedButton.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }, 10)
  }, [props.name])

  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          control={control}
          rules={registerConfig}
          name={props.name}
          render={({ field: { onChange, ref, value } }) => {
            return (
              <div ref={containerRef}>
                {options.map(item => (
                  <div key={`${item.prev}${item.value}`}>
                    <RadioButton
                      {...props}
                      name={props.name}
                      onChange={onChange}
                      additionalAction={additionalAction}
                      radioRef={ref}
                      icon={item.icon}
                      title={item.label}
                      value={item.value}
                      checked={item.value === value}
                      classNameWrapper={classNameWrapper}
                      isMobileSelector={isMobileSelector}
                      type={type}
                    />
                  </div>
                ))}
              </div>
            )
          }}
        />
      )}
    </ConnectForm>
  )
}
