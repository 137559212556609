import { FC, useState } from 'react'

import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import EarnedDailyRewardModal from '@/entities/earned-daily-reward-modal'

import { useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Modal } from '@/shared/ui/modal'
import { TModalModifers } from '@/shared/ui/modal/modal.type'

import styles from './RegisterDemoUser.module.scss'

interface IProps {
  isOpen: boolean
  data: IUsers.ResponseDemoRegister
}
export const RegisterDemoUser: FC<IProps> = ({ isOpen, data }) => {
  const navigate = useNavigate()
  const form = useForm()
  const { getFieldStatic } = useTranslationField()
  type TStep = 0 | 1 | 2
  const [step, setStep] = useState<TStep>(0)
  const modifers = ['simple', 'tiny', 'absolute', 'deposit'] as TModalModifers[]

  const onNextStep = async (currentStep?: TStep) => {
    if (currentStep === 1) {
      setStep(currentStep)
    }
    if (currentStep === 1) {
      setStep(currentStep)
    }
    if (currentStep === 2) {
      setStep(currentStep)
    }
    if (!currentStep) {
      navigate('/')
    }
  }

  const modalComponent = {
    0: (
      <Modal
        isOpen={isOpen}
        onClose={() => onNextStep(1)}
        disabledPortal
        modifers={modifers}
        disabledClickOutside>
        <div className={clsx(styles.wrapper, styles.two)}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: getFieldStatic('bonuses_to_account')
            }}></div>
          <div className={styles.text}>{getFieldStatic('continue_luck')}</div>
          <div className={styles.form}>
            <FormWrapper methods={form} onSubmit={() => onNextStep(2)}>
              <Input
                value={String(data.login_id)}
                name='user_id'
                placeholder={getFieldStatic('user')}
              />
              <Input
                value={data.password}
                name='password'
                placeholder={getFieldStatic('login_pass_new')}
              />
              <div className={styles.button}>
                <Button modifiers={['red']}> {getFieldStatic('next')}</Button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </Modal>
    ),
    1: <EarnedDailyRewardModal quizStep={1} isOpen={isOpen} onClose={() => onNextStep(2)} />,
    2: <EarnedDailyRewardModal quizStep={2} isOpen={isOpen} onClose={() => onNextStep()} />
  }

  return <div className={styles.wrapper}>{modalComponent?.[step] || modalComponent[0]}</div>
}
