export const getContentType = () => ({
  'Content-Type': 'application/json'
})

export const errorCatch = (error: any): string => {
  if (error.response && error.response.data) {
    return typeof error.response.data.detail === 'object'
      ? error.response.data.detail[0].msg
      : error.response.data.detail
  }
  return error.message
}
