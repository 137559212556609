/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'
import { useAuthStore } from '@/app/zustand-store'

import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LoginIconCn,
  LoginIconEn
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { validEmail, validEmailOrUserId } from '@/shared/regex'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

import { useAuthActions } from '../api'

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
}
export const Login: FC<IProps> = ({ page, widget = false }) => {
  const { login } = useAuthActions()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  const { changeAuthorized } = useAuthStore()
  const formLogin = useForm({ mode: 'onBlur' })
  const {
    formState: { errors }
  } = formLogin

  const onLogin = async (values: FieldValues) => {
    const dataLogin = values as IUsers.IEmailPassword
    try {
      if (validEmail.test(values.email)) {
        await login.mutateAsync({ email: dataLogin.email, password: dataLogin.password })
      } else {
        await login.mutateAsync({ user_id: dataLogin.email, password: dataLogin.password })
      }
      formLogin.reset({ email: '', password: '' })
      changeAuthorized(true)

      page('')
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  if (login.isLoading) return <Loader />

  return (
    <FormWrapper methods={formLogin} onSubmit={onLogin} className='LoginForm'>
      {locale === 'en' ? <LoginIconEn className='logo' /> : <LoginIconCn className='logo' />}
      <div className='formGroup'>
        <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>
        <div className='title'>{getFieldStatic('login_account')}</div>
        <div className='auth-block-wrap'>
          <div>
            <Input
              mask=''
              name='email'
              type='email'
              connect
              placeholder={getFieldStatic('user_id_email')}
              errorText={errors?.email?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                pattern: {
                  value: validEmailOrUserId,
                  message: getFieldStatic('correct_email_user_id')
                }
              }}
            />
          </div>
          <div className='inputPass'>
            <Input
              mask=''
              name='password'
              type='password'
              connect
              isPassword
              placeholder={getFieldStatic('login_pass')}
              errorText={errors?.password?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                minLength: {
                  value: 8,
                  message: getFieldStatic('minimum_characters') + ' - 8'
                }
              }}
            />
          </div>
          <Button modifiers={['red', 'full']} classNameContainer='buttonContainer'>
            {getFieldStatic('log_in')}
          </Button>
        </div>
        <div className='info'>
          <div className='infoText'>{`${getFieldStatic('have_not_account')}`}</div>
          <span
            onClick={() => page('registration')}
            className='link'>{`${getFieldStatic('register')}`}</span>
        </div>
        <div className='forgotPassword'>
          <span onClick={() => page('forgot-password')} className='link'>
            {getFieldStatic('forgot_pass')}
          </span>
        </div>
      </div>
    </FormWrapper>
  )
}

export default Login
