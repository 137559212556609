import { FC, PropsWithChildren } from 'react'

import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn
} from 'react-hook-form'

type TProps = PropsWithChildren & {
  onSubmit: SubmitHandler<FieldValues>
  onError?: SubmitErrorHandler<FieldValues>
  methods: UseFormReturn<any, any, undefined>
  className?: string
  style?: {
    [key: string]: string
  }
}

export const FormWrapper: FC<TProps> = ({
  children,
  onSubmit = () => {},
  onError = () => {},
  methods,
  className,
  style = {}
}) => {
  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onSubmit={methods.handleSubmit(onSubmit, onError)}
        onError={e => {}}
        noValidate
        style={style}>
        {children}
      </form>
    </FormProvider>
  )
}
