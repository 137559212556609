import { FC } from 'react'

import { QuizLayout } from '@/features/quiz/ui/layout'

import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutTwo } from '@/shared/ui/quiz-layout/quiz-layout-2'

import { backgroundQuiz_4, image_22, image_22_mobi } from '@/Assets/img/quiz'

import { TQuiz } from '../..'

export const QuizTwo: FC<TQuiz> = ({ onNextStep }) => {
  const { getFieldStatic } = useTranslationField()
  const { isMobile } = useIsMobile(768)

  return (
    <QuizLayout background={backgroundQuiz_4}>
      <QuizLayoutTwo
        image={isMobile ? image_22_mobi : image_22}
        onNextStep={onNextStep}
        title={getFieldStatic('questionTwoVariant3')}
      />
    </QuizLayout>
  )
}
