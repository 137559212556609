import { FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { IPropsConnectForm } from '../form.interface'

export const ConnectForm: FC<IPropsConnectForm> = ({ children }) => {
  const methods = useFormContext()
  return children({ ...methods })
}
