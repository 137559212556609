// Библиотечные компоненты
import { Dispatch, FC } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'

import { useAuthActions } from '@/features/auth/api'

import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LogoChiJSX,
  LogoEnJSX
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { validEmail } from '@/shared/regex'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

// Компоненты

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
  setEmail: Dispatch<React.SetStateAction<any>>
}
export const ForgotPassword: FC<IProps> = ({ page, widget = false, setEmail }) => {
  const { resetPassword } = useAuthActions()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  const methods = useForm({
    mode: 'onBlur'
  })

  const {
    formState: { errors }
  } = methods

  const handlerFormData = async (values: FieldValues) => {
    const data = { form: values, lang: locale }
    setEmail({ email: values.email })

    try {
      await resetPassword.mutateAsync(data as IUsers.IResetCodePasswordForm)
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  if (resetPassword.isLoading) return <Loader />

  return (
    <FormWrapper methods={methods} onSubmit={handlerFormData} className='LoginForm'>
      {locale === 'en' ? <LogoEnJSX className='logo' /> : <LogoChiJSX className='logo' />}
      <div className='formGroup'>
        <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>
        <div className='title'>{getFieldStatic('forgot_pass')}</div>
        <div className='auth-block-wrap'>
          <>
            <Input
              mask=''
              name='email'
              type='email'
              connect
              placeholder={getFieldStatic('login_email')}
              errorText={errors?.email?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                pattern: {
                  value: validEmail,
                  message: getFieldStatic('correct_email')
                }
              }}
            />
          </>
          <Button
            type='submit'
            modifiers={['red']}
            disabled={resetPassword.isLoading}
            classNameContainer='buttonContainer'>
            {getFieldStatic('confirm')}
          </Button>
        </div>
        <div className='info' style={{ marginBottom: '6px' }}>
          <div className='infoText'>{`${getFieldStatic('have_account')}`}</div>
          <span className='link' onClick={() => page('login')}>
            {getFieldStatic('log_in')}
          </span>
        </div>
        <div className='info'>
          <div className='infoText'>{`${getFieldStatic('have_not_account')}`}</div>
          <span
            onClick={() => page('registration')}
            className='link'>{`${getFieldStatic('register')}`}</span>
        </div>
      </div>
    </FormWrapper>
  )
}

export default ForgotPassword
