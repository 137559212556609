import React, { FC } from 'react'

import clsx from 'clsx'

import { TQuiz } from '@/widgets/quiz'

import { QuizContent } from '@/entities/quiz-content'

import styles from './quizLayout.module.scss'

interface IProps extends TQuiz {
  image: string
  title?: string
  descriptions: string[]
}
export const QuizLayoutFour: FC<IProps> = ({ onNextStep, image, title, onFinal, descriptions }) => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.image, 'animate__animated animate__backInLeft')}>
        <img src={image} alt='img' />
      </div>

      <QuizContent maxWidth='796px' classNameWrapper='left' onFinal={onFinal}>
        <div className={'text-wrapper'} style={{ textAlign: 'inherit', maxWidth: '503px' }}>
          {descriptions.map(item => (
            <p className={'text'}>{item}</p>
          ))}
        </div>
      </QuizContent>
    </div>
  )
}
