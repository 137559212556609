import React, { FC } from 'react'

import cn from 'clsx'
import { FormattedMessage } from 'react-intl'

import { useTranslationField } from '@/shared/hooks'

import { ReactComponent as MenuIcon } from '@/Assets/svg/menu-icon.svg'

import styles from './CouponTabs.module.sass'
import { ICouponTabs } from './coupon-tabs.interface'

const tabNames = ['singles', 'accumulator'] as const

const CouponTabs: FC<ICouponTabs> = ({ activeTab, setActiveTab, setIsBetTypeSelectIsOpened }) => {
  const { getFieldStatic } = useTranslationField()
  const onClickTab = (index: number) => setActiveTab(index)
  const onClickMenuButton = () => setIsBetTypeSelectIsOpened(true)

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {tabNames.map((tab, index) => (
          <React.Fragment key={index}>
            <button
              type='button'
              // data-content={<FormattedMessage id={tab} />}
              data-content={getFieldStatic(tab)}
              onClick={() => onClickTab(index)}
              className={cn(activeTab === index && styles.active)}></button>
            {/* {index !== tabNames.length - 1 && <div />} */}
          </React.Fragment>
        ))}
      </div>
      {/* <button
				type='button'
				className={styles.button}
				onClick={onClickMenuButton}
			>
				<MenuIcon />
			</button> */}
    </div>
  )
}

export default CouponTabs
