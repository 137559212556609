import { typeAction, typeMessageState } from '../types'

const message: typeMessageState = {
  type: null,
  message: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = message, action: typeAction) {
  const { type, value } = action

  switch (type) {
    case 'SET_MESSAGE':
      return value
    default:
      return state
  }
}
