import { useEffect } from 'react'

import { RouterProvider } from 'react-router-dom'

import { useMatomo } from './hooks/useMatomo'
import MainProvider from './providers'
import { router } from './providers/RouterProviders'

const AppRoot = () => {
  useMatomo()

  return (
    <MainProvider>
      <RouterProvider router={router} />
    </MainProvider>
  )
}

export default AppRoot
