import { useEffect } from 'react'

export const useLockedScroll = (isLocked?: boolean | undefined) => {
  const scrollY = window.scrollY
  const scrollX = window.scrollX

  const handleLockScroll = () => {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
    document.body.style.width = '100%'
    document.body.style.left = `-${scrollX}px`
  }

  const handleUnlockScroll = () => {
    document.body.style.position = ''
    // document.body.style.top = '0px'
    document.body.style.left = ''
    document.body.style.width = ''
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (!isLocked) return

    handleLockScroll()

    return () => {
      handleUnlockScroll()
    }
  }, [isLocked])

  const lockScroll = (isLocked: boolean) => {
    if (isLocked) {
      handleLockScroll()
    } else {
      handleUnlockScroll()
    }
  }

  return {
    lockScroll,
    scrollToTop
  }
}
