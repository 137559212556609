import { FC, useState } from 'react'

import { KABAM_POSTBACK } from '@/app/hooks/useForwardPostBack'

import { Auth } from '@/widgets/auth'
import { TQuiz } from '@/widgets/quiz'

import { useProfileActions } from '@/features/profile/api'

import PostBackService from '@/shared/services/postback.service'

import { LogoChiJSX, LogoEnJSX } from '@/shared/assets/common/svg'
import { DemoUserImg, RegularUserImg } from '@/shared/assets/quiz/img'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
import { Button, RadioButton } from '@/shared/ui/form'
import { addMessageToast } from '@/shared/utils'

import { ReactComponent as DemoUserCn } from '../../../../shared/assets/quiz/svg/TitleDemoUserCn.svg'
import { ReactComponent as RegularUserCn } from '../../../../shared/assets/quiz/svg/TitleRegularUserCn.svg'
import { ReactComponent as DemoUserEn } from '../../../../shared/assets/quiz/svg/TittleDemoUser.svg'
import { ReactComponent as RegularUserEn } from '../../../../shared/assets/quiz/svg/TittleRegularUser.svg'
import { RegisterDemoUser } from '../register-demo-user'

import styles from './SelectUserType.module.scss'
import { useAuthStore } from '@/app/zustand-store'

type MobileUserType = 'Demo user' | 'Regular user'

export const SelectUserType: FC<TQuiz> = ({ background }) => {
  const { getFieldStatic, locale } = useTranslationField()
  const [, setPageName] = useState('')
  const [isOpenSelectModal, setIsOpenSelectModal] = useState(true)
  const [isOpenDemoUser, setIsOpenDemoUser] = useState(false)
  const [isOpenRegularUser, setIsOpenRegularUser] = useState(false)
  const { isMobile } = useIsMobile()
  const { onRegisterUser } = useProfileActions()
  const [mobileUserSelected, setMobileUserSelected] = useState<MobileUserType>()
  const [demoUser, setDemoUser] = useState<IUsers.ResponseDemoRegister | null>(null)
  const { changeAuthorized } = useAuthStore()

  const chooseDemoUser = async () => {
    try {
      const { data } = await onRegisterUser.mutateAsync()
      changeAuthorized(true)
      const kabamInfo = JSON.parse(localStorage.getItem(KABAM_POSTBACK) || '{}')
      const { subid } = kabamInfo

      if (subid) {
        PostBackService.sendPostBackKabam({ subid }).then(res => {
          localStorage.removeItem(KABAM_POSTBACK)
        })
      }

      setDemoUser(data)
      setIsOpenDemoUser(true)
      setIsOpenSelectModal(false)
    } catch (error) {
      addMessageToast(error)
    }
  }

  const chooseRegularUser = () => {
    setIsOpenRegularUser(true)
    setIsOpenSelectModal(false)
  }

  const chooseMobileUserType = (type: MobileUserType) => {
    setMobileUserSelected(type)
  }

  const chooseMobileUser = () => {
    if (mobileUserSelected === getFieldStatic('demo_user')) {
      chooseDemoUser()
    } else {
      chooseRegularUser()
    }
  }

  return (
    <>
      {isOpenSelectModal && (
        <div
          className={styles.selectUserType}
          style={{
            backgroundImage: `url(${background})`
          }}>
          {isMobile ? (
            <>
              <div className={styles.contentBlock}>
                <div className={styles.elements}>
                  <div className={styles.logo}>
                    {locale === 'en' ? <LogoEnJSX style={{ width: '132px' }} /> : <LogoChiJSX />}
                  </div>
                  <div className={styles.mobileTitle}>{getFieldStatic('choose_user_type')}</div>
                  <div>
                    <div
                      className={styles.selectBlock}
                      onClick={() =>
                        chooseMobileUserType(getFieldStatic('demo_user') as MobileUserType)
                      }>
                      <RadioButton
                        type='radio'
                        title={getFieldStatic('demo_user')}
                        name={getFieldStatic('demo_user')}
                        checked={mobileUserSelected === getFieldStatic('demo_user')}
                        isMobileSelector
                        className={styles.checkLabel}
                      />
                      <div className={styles.text}>{getFieldStatic('demo_user_info_mobile')}</div>
                    </div>
                    <div
                      className={styles.selectBlock}
                      onClick={() =>
                        chooseMobileUserType(getFieldStatic('regular_user') as MobileUserType)
                      }>
                      <RadioButton
                        type='radio'
                        title={getFieldStatic('regular_user')}
                        name={getFieldStatic('regular_user')}
                        checked={mobileUserSelected === getFieldStatic('regular_user')}
                        isMobileSelector
                        className={styles.checkLabel}
                      />
                      <div className={styles.text}>
                        {getFieldStatic('regular_user_info_mobile')}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  modifiers={['red']}
                  onClick={chooseMobileUser}
                  classNameContainer={styles.button}
                  disabled={onRegisterUser.isLoading || !mobileUserSelected}>
                  {getFieldStatic('next')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.title}>{getFieldStatic('choose_user_type')}</div>
              <div className={styles.content}>
                <div className={styles.contentBlock}>
                  <div className={styles.elements}>
                    {locale === 'en' ? <DemoUserEn /> : <DemoUserCn />}
                    <img src={DemoUserImg} />
                    <div className={styles.info}>{getFieldStatic('demo_user_info')}</div>
                  </div>

                  <Button
                    modifiers={['red']}
                    onClick={chooseDemoUser}
                    disabled={onRegisterUser.isLoading}
                    classNameContainer={styles.button}>
                    {getFieldStatic('choose')}
                  </Button>
                </div>
                <div className={styles.contentBlock}>
                  <div className={styles.elements}>
                    {locale === 'en' ? <RegularUserEn /> : <RegularUserCn />}
                    <img src={RegularUserImg} />
                    <div className={styles.info}>{getFieldStatic('regular_user_info')}</div>
                  </div>

                  <Button
                    modifiers={['red']}
                    onClick={chooseRegularUser}
                    disabled={onRegisterUser.isLoading}
                    classNameContainer={styles.button}>
                    {getFieldStatic('choose')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {isOpenDemoUser && demoUser && <RegisterDemoUser data={demoUser} isOpen={isOpenDemoUser} />}
      {isOpenRegularUser && <Auth pageName={'registration'} setPageName={setPageName} />}
    </>
  )
}
