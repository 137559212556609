import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { useGameList } from '@/features/games/games-list/api'

import {
  foreground_slider,
  game_slider_1,
  game_slider_2,
  game_slider_3
} from '@/shared/assets/games/img'
import { useIsMobile } from '@/shared/hooks'
import { IGames } from '@/shared/types/games.types'
import { Loader } from '@/shared/ui/loader'
import { SuspenseWrapper } from '@/shared/ui/suspense'

import styles from './GamesSlider.module.scss'

const OwlCarousel = React.lazy(() => import('react-owl-carousel3'))

export const GamesSlider = () => {
  const [gamesList, setGamesList] = useState<IGames.IGameData[] | null>(null)

  const { isMobile } = useIsMobile(1180)
  const { games, isLoading } = useGameList(12)

  useEffect(() => {
    if (games) {
      games.length = 3
      setGamesList(games)
    }
  }, [games])

  if (isLoading || !gamesList?.length) return <Loader />
  return (
    <div className={clsx(styles.wrapper)}>
      <SuspenseWrapper>
        <OwlCarousel
          className='owl-theme'
          margin={10}
          nav={false}
          items={1}
          dots={true}
          navText={[
            '<button class="buttonBlackHoverRedBorderGold  left" data-content="‹"/>',
            '<button class="buttonBlackHoverRedBorderGold  right" data-content="›"/>'
          ]}
          responsive={{
            1180: {
              nav: true
            }
          }}
          animateIn='animate__animated animate__zoomIn'
          animateOut='animate__animated animate__zoomOut'>
          {gamesList?.map((item, index: number) => (
            <SuspenseWrapper key={item.id}>
              <Link to={`/games/item/${item.id}`} className={styles.item} datatype={item.game_name}>
                <img className={styles.image} src={item.image_jpg} alt='img promo' />
                {isMobile && (
                  <img className={styles.foreground} src={foreground_slider} alt='foreground' />
                )}
              </Link>
            </SuspenseWrapper>
          ))}
        </OwlCarousel>
      </SuspenseWrapper>
    </div>
  )
}
