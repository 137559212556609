import { create } from 'zustand'

interface AuthState {
  authorized: boolean
  changeAuthorized: (authorized: boolean) => void
}

export const useAuthStore = create<AuthState>(set => ({
  authorized: !!localStorage.getItem('access') || false,
  changeAuthorized: (authorized: boolean) => set(state => ({ authorized: authorized }))
}))
