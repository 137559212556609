/**
 * Возвращает строку, которая из вида 10000 примет вид 10 000
 * @param num число
 * */

export const reFormatNum = (num?: number, symbol?: string) => {
  if (typeof Number(num) !== 'number' && !isNaN(Number(num))) return 0

  return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ` ${(num && symbol) || ''}`
}
