// Библиотечные компоненты
import { FC, useState } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'

import { useAuthActions } from '@/features/auth/api'
import FourDigitCode from '@/features/auth/ui/FourDigitCode'

import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LogoChiJSX,
  LogoEnJSX
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { validEmail } from '@/shared/regex'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

// Компоненты

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
  email: string
  localValue: string | null
}
export const ForgotPasswordCode: FC<IProps> = ({ page, widget = false, email, localValue }) => {
  const { sendCodeResetPassword } = useAuthActions()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  const [step, setStep] = useState(0)
  const methods = useForm({
    mode: 'onBlur'
  })

  const {
    formState: { errors },
    watch,
    getValues
  } = methods

  const handlerFormData = async (values: FieldValues) => {
    try {
      await sendCodeResetPassword.mutateAsync(values as IUsers.IResetCodePasswordForm)
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  const onNextStep = (data: any) => {
    setStep(1)
    methods.setValue('email', data.email)
    methods.setValue('code', data.values.join(''))
  }

  if (sendCodeResetPassword.isLoading) return <Loader />

  if (!step) {
    return (
      <FourDigitCode
        page={page}
        onForgotNext={onNextStep}
        authDataForm={{ email }}
        localValue={localValue}
      />
    )
  }

  return (
    <FormWrapper methods={methods} onSubmit={handlerFormData} className='LoginForm'>
      {locale === 'en' ? <LogoEnJSX className='logo' /> : <LogoChiJSX className='logo' />}
      <div className='formGroup'>
        <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>
        <>
          <div className='title'>{getFieldStatic('reset_pass')}</div>
          <Input
            mask=''
            name='email'
            type='email'
            value={watch('email')}
            connect
            placeholder={getFieldStatic('login_email')}
            errorText={errors?.email?.message}
            registerConfig={{
              required: getFieldStatic('required_field'),
              pattern: {
                value: validEmail,
                message: getFieldStatic('correct_email')
              }
            }}
          />
          <Input
            mask=''
            name='new_password'
            type='password'
            connect
            isPassword
            placeholder={getFieldStatic('login_pass_new')}
            errorText={errors?.new_password?.message}
            registerConfig={{
              required: getFieldStatic('required_field'),
              minLength: {
                value: 8,
                message: getFieldStatic('minimum_characters') + ' - 8'
              }
            }}
          />
          <Input
            mask=''
            name='repeat_password'
            type='password'
            isPassword
            connect
            placeholder={getFieldStatic('login_pass_repeat')}
            errorText={errors?.repeat_password?.message}
            registerConfig={{
              required: getFieldStatic('required_field'),
              minLength: {
                value: 8,
                message: getFieldStatic('minimum_characters') + ' - 8'
              },
              validate: value =>
                (value as any) === getValues().new_password ||
                getFieldStatic('passwords_dont_match')
            }}
          />
          <Button
            modifiers={['red']}
            classNameContainer='buttonContainer'
            type='submit'
            disabled={sendCodeResetPassword.isLoading}>
            {getFieldStatic('confirm')}
          </Button>
          <span onClick={() => page('login')} style={{ marginBottom: '5px' }}>
            {getFieldStatic('login')}
          </span>
          <span onClick={() => page('registration')} style={{ marginBottom: '30px' }}>
            {getFieldStatic('register')}
          </span>
        </>
      </div>
    </FormWrapper>
  )
}
