import { FC } from 'react'

import { clsx } from 'clsx'

import { bonuseBg } from '@/shared/assets/profile/img'
import { useIsMobile } from '@/shared/hooks'
import { useTranslationField } from '@/shared/hooks'
import { Button } from '@/shared/ui/form'
import { Modal } from '@/shared/ui/modal'
import { TModalModifers } from '@/shared/ui/modal/modal.type'

import {
  RewardSparkles,
  RewardSparklesMobile,
  rewardLeaves,
  rewardMoney,
  rewardMoneyMobile
} from '@/Assets/img/reward'

import styles from './EarnedDailyRewardModal.module.scss'

interface IProps {
  onClose: () => void
  isOpen?: boolean
  quizStep?: number
}

const EarnedDailyRewardModal: FC<IProps> = ({ onClose, isOpen, quizStep }) => {
  const { getFieldStatic } = useTranslationField()

  const closeModal = () => {
    onClose()
  }

  const modifers = ['simple', 'tiny', 'absolute', 'deposit'] as TModalModifers[]

  return (
    <>
      <Modal
        isOpen={!!isOpen}
        modifers={modifers}
        onClose={onClose}
        background={bonuseBg}
        disabledClickOutside>
        <div className={styles.content}>
          {quizStep && quizStep === 1 ? (
            <div
              className={clsx(styles.title, styles.max)}
              dangerouslySetInnerHTML={{
                __html: getFieldStatic('daily_bonuses')
              }}></div>
          ) : quizStep && quizStep === 2 ? (
            <div className={clsx(styles.title, styles.max)}>{getFieldStatic('collect_free')}</div>
          ) : (
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: getFieldStatic('rewardTitle')
              }}
            />
          )}

          <div className={styles.reward}>
            {quizStep !== 1 && (
              <span>
                +{quizStep === 2 ? 1000 : 500} {getFieldStatic('betcoins')}{' '}
              </span>
            )}
          </div>
          <div className={clsx(styles.button, quizStep && styles.buttonMax)}>
            <Button modifiers={['red']} onClick={closeModal}>
              {quizStep === 1 ? getFieldStatic('ok') : getFieldStatic('collect')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EarnedDailyRewardModal
