import { FC, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import cn from 'clsx'

import { BasketBets } from '@/widgets/sports/bets-basket/basket.interface'
import { submitAllOdinarBet, useBasket } from '@/widgets/sports/bets-basket/useBasket'

import ButtonSelect from '@/entities/profile/select/button-select'

import { useErrorAlert } from '@/shared/hooks/useErrorAlert'
import { useAppSelector } from '@/shared/model'
import InputCounter from '@/shared/sports/input-counter/InputCounter'
import { Button } from '@/shared/ui/form'

import { useExpressBet } from '../../api/useExpressBet'

import styles from './ControlBlock.module.scss'
import { IControlBlock } from './control-block.interface'
import { messages } from '@/i18n/messages'

const tabs = ['Bet', 'Promo code']

const ControlBlock: FC<IControlBlock> = ({
  setIsSettingsOpened,
  singl = true,
  coefficient = 0
}) => {
  const { addErrorAlert } = useErrorAlert()

  const queryClient = useQueryClient()
  const successCallback = () => {
    queryClient.invalidateQueries(['school list'])
  }
  const [activeTab, setActiveTab] = useState(0)

  const [selectValue, setSelectValue] = useState('')
  const [inputValue, setInputValue] = useState(0)

  const { locale } = useAppSelector(state => state.defaultReducer)

  const cart = useBasket()
  const marketIds = cart.map(item => item.market.pk)
  const { mutateAsync } = useExpressBet(marketIds)

  const [submitCart, setSubmitCart] = useState<Array<BasketBets.Basket>>(cart)

  const onClick = () => {
    const input = document.querySelector<HTMLInputElement>("input[name='stake']")
    const value = input?.value

    if (!value || value === '0') return null

    mutateAsync(Number(value)).then(() => {
      input!.value = '0'
    })
  }
  const handleStake = () => {
    document.querySelectorAll<HTMLInputElement>("input[name='stake']").forEach((el, index) => {
      const val = Number.parseInt(el.value)
      if (!isNaN(val)) {
        setSubmitCart(prevState => {
          prevState[index].stake = val
          return [...prevState]
        })
      }
    })
  }

  useEffect(() => {
    setSubmitCart(cart)
    setTimeout(handleStake, 100)
  }, [cart])

  useEffect(() => {
    window.addEventListener('stake', handleStake)
    return () => {
      window.removeEventListener('stake', handleStake)
    }
  })

  const onClickSettingsButton = () => setIsSettingsOpened(true)

  return (
    <div className={styles.controlBlock}>
      {!singl && (
        <div className={styles.controlHeader}>
          <div className={styles.currency}>₿</div>
          <div className={styles.info}>
            <h4></h4>
            <div>
              <span>{messages[locale].over_all_odds}</span>
              <span>{coefficient.toFixed(2)}</span>
              {/*<ArrowLeftIcon />*/}
              <span></span>
            </div>
          </div>
          {/*<button type='button' onClick={onClickSettingsButton}>*/}
          {/*	<SettingsIcon />*/}
          {/*</button>*/}
        </div>
      )}

      {!singl && (
        <div className={styles.tabsContainer}>
          {/*<div className={styles.tabs}>*/}
          {/*	{tabs.map((tab, index) => (*/}
          {/*		<button key={index} type='button' onClick={() => setActiveTab(index)}>*/}
          {/*			<span className={cn(activeTab === index && styles.active)}>*/}
          {/*				{tab}*/}
          {/*			</span>*/}
          {/*		</button>*/}
          {/*	))}*/}
          {/*</div>*/}
          <div className={styles.tabContent}>
            {activeTab === 0 && (
              <>
                <InputCounter
                  value={inputValue}
                  setValue={setInputValue}
                  className={styles.inputCounter}
                />
                {/*<span>from 0 to ∞ CNY</span>*/}
              </>
            )}
            {activeTab === 1 && (
              <>
                <ButtonSelect
                  options={tabs}
                  value={selectValue}
                  onChange={setSelectValue}
                  placeholder='Select promo code'
                  className={styles.select}
                />
                <span>No promo codes</span>
              </>
            )}
          </div>
        </div>
      )}

      <div className={styles.total}>
        {!singl && (
          <div>
            <p>{messages[locale].potential_winnings}</p>
            <p>{(coefficient * inputValue).toFixed(2)} ₿</p>
          </div>
        )}

        <Button
          className={styles.betButton}
          type='button'
          modifiers={['gold']}
          onClick={() => {
            singl ? submitAllOdinarBet(submitCart, addErrorAlert, successCallback) : onClick()
          }}>
          {messages[locale].place_bet}
        </Button>
      </div>
    </div>
  )
}

export default ControlBlock
