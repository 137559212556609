import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import AuthService from '@/shared/services/auth/auth.service'
import UserService from '@/shared/services/user.service'

import { IUsers } from '@/shared/types/user.types'

export const useProfileActions = () => {
  const queryClient = useQueryClient()

  const sendProfileCode = useMutation(
    ['edit user data'],
    (email: string) => UserService.sendUserCode(email),
    {
      onError: (error: AxiosError) => {}
    }
  )

  const onChangePassword = useMutation(
    ['changePassword'],
    (changePasswordData: IUsers.IChangePasswordData) =>
      UserService.changePassword(changePasswordData),
    {
      onSuccess: res => {},

      retry: 0
    }
  )

  const onChangeUser = useMutation(
    ['edit user data'],
    (editUserData: IUsers.TypeChangeUserData) => UserService.editUserData(editUserData),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(['get user data'])
      },
      onError: (error: AxiosError) => {}
    }
  )

  const onRegisterUser = useMutation(['register demo user'], () => AuthService.registerDemo(), {
    onSuccess: res => {
      queryClient.invalidateQueries(['get user data'])
    },
    onError: (error: AxiosError) => {}
  })

  return { sendProfileCode, onChangePassword, onChangeUser, onRegisterUser }
}
