import { FC } from 'react'

import { useAppSelector } from '@/shared/model'

import { ReactComponent as ArrowBackIcon } from '@/Assets/svg/arrow-back.svg'
import { ReactComponent as TrashIcon } from '@/Assets/svg/trash-46x46.svg'

import styles from './CouponHeader.module.sass'
import { ICouponHeader } from './coupon-header.interface'
import { messages } from '@/i18n/messages'

const CouponHeader: FC<ICouponHeader> = ({
  isBetTypeSelectIsOpened,
  isSettingsOpened,
  setOpen
}) => {
  const { locale } = useAppSelector(state => state.defaultReducer)
  const getHeading = () => {
    if (isSettingsOpened) return messages[locale].bet_slip
    if (isBetTypeSelectIsOpened) return messages[locale].bet_types
    return messages[locale].bet_slip
  }

  const resetBets = () => {
    localStorage.setItem('cart', JSON.stringify([]))
    window.dispatchEvent(new Event('storage'))
  }

  return (
    <div className={styles.header}>
      <button
        type='button'
        onClick={() => {
          setOpen(false)
        }}>
        <ArrowBackIcon />
      </button>
      <h2>{getHeading()}</h2>
      <button className={styles.cart} onClick={resetBets}>
        <TrashIcon />
      </button>
    </div>
  )
}

export default CouponHeader
