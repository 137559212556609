import { useState } from 'react'

import { SelectUserType } from '@/features/quiz/ui/select-user-type'

import { backgroundQuiz_53 } from '@/Assets/img/quiz'

import { QuizLang } from '../slices/quiz_lang'

import { QuizOne } from './quiz_1'
import { QuizTwo } from './quiz_2'
import { QuizThree } from './quiz_3'
import { QuizFour } from './quiz_4'

const quizzes = [QuizLang, QuizOne, QuizTwo, QuizThree, QuizFour, SelectUserType]

export const QuizVariant3 = () => {
  const [step, setStep] = useState(0)

  const onNextStep = () => {
    setStep(prev => prev + 1)
  }

  const CurrentQuiz = quizzes[step]
  return (
    <>
      <CurrentQuiz onNextStep={onNextStep} onFinal={onNextStep} background={backgroundQuiz_53} />
    </>
  )
}
