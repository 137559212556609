import { basketball_bg, football_bg, single_bet_bg } from '../assets/experts/img'

export const getExpertBackground = (type: string) => {
  const expertType = type.split(' ')[0].toLowerCase()

  switch (expertType) {
    case 'football':
      return football_bg
    case 'basketball':
      return basketball_bg
    case 'single':
      return single_bet_bg
    default:
      return football_bg
  }
}
