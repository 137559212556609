import { betDictionary } from '../constants'
import { typeAction, typeCartCardEvent, typeDefaultShopState, typeLeaderboard } from '../types'

const defaultShopState: typeDefaultShopState = {
  types: [],
  cart: {
    products: [],
    show: false,
    over_all_odds: 0,
    potential_winnings: 0,
    type: 1,
    stake: 0,
    stake_step: 100
  },
  update_markets: [],
  bet_dictionary: betDictionary
}

export default function sport(state = defaultShopState, action: typeAction) {
  const { type, value } = action
  switch (type) {
    case 'SET_TYPES':
      return {
        ...state,
        types: [...value]
      }
    case 'SET_TOURNAMENTS':
      return {
        ...state,
        tournaments: { ...value }
      }
    case 'SET_GAMES':
      return {
        ...state,
        games: { ...value }
      }
    case 'SET_MARKETS':
      return {
        ...state,
        markets: { ...value }
      }
    case 'SET_CART':
      value.stake = state.cart?.stake ? state.cart?.stake : 0
      value.stake_step = state.cart?.stake_step ? state.cart?.stake_step : 100
      value.type = state.cart?.type ? state.cart?.type : 0
      if (value.type === 0) {
        const newValue = value.products.filter(
          (item: typeCartCardEvent) => new Date(item.start_date) > new Date()
        )
        value.products = newValue.map((item: typeCartCardEvent, index: number) => {
          if (index === 0) {
            value.over_all_odds = item.market.market
          } else {
            value.over_all_odds = value.over_all_odds * item.market.market
          }
          return item
        })
        value.potential_winnings = value.over_all_odds * value.stake
      } else {
        value.over_all_odds = 0
        value.potential_winnings = 0
      }
      localStorage.setItem('cart', JSON.stringify(value))
      return {
        ...state,
        cart: { ...value },
        update_markets: value.products.map((item: typeCartCardEvent) => item.market.id)
      }
    case 'SET_ANALYTICS':
      return {
        ...state,
        analytics: { ...value }
      }
    case 'SET_LEADERBOARD': {
      const value = action.value as typeLeaderboard
      return {
        ...state,
        leaderboard: { ...value }
      }
    }
    default:
      return state
  }
}
