import { useQuery } from '@tanstack/react-query'

import UserService from '@/shared/services/user.service'

import { IHistoryFiltersForm } from '@/shared/types/global.types'

export const useBettingHistory = (historyFilters: IHistoryFiltersForm | null) => {
  const result = useQuery(
    ['get all tournaments', historyFilters],
    () => UserService.getBetsList(historyFilters || {}),
    {
      select: ({ data }) => data,
      retry: 0,
      keepPreviousData: true
    }
  )

  return result
}
