import { useEffect, useState } from 'react'

import { AxiosError } from 'axios'

import SportService from '@/shared/services/sport.service'

import { ISport } from '@/shared/types/sport.types'

import { BasketBets } from './basket.interface'

export const useBasket = () => {
  const [cart, setCart] = useState<Array<BasketBets.Basket>>([])

  const handleUserKeyPress = () => {
    const cart_local = localStorage.getItem('cart')
    if (cart_local) {
      setCart(JSON.parse(cart_local))
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleUserKeyPress)

    return () => {
      window.removeEventListener('storage', handleUserKeyPress)
    }
  })

  useEffect(() => {
    handleUserKeyPress()
  }, [])

  return cart
}

export const submitAllOdinarBet = async (
  cart: Array<BasketBets.Basket>,
  errorAlert: (error: AxiosError<any, any>) => void,
  callbackSuccess?: () => void
) => {
  const newCart: BasketBets.Basket[] = []

  const processBetPromises = async (index: number) => {
    if (index >= cart.length) {
      localStorage.setItem('cart', JSON.stringify(newCart))
      window.dispatchEvent(new Event('storage'))

      return
    }

    const item = cart[index]

    if (item.stake === undefined || item.stake <= 0) {
      newCart.push(item)
      await processBetPromises(index + 1)
    } else {
      const formData = new FormData()
      formData.append('amount', item.stake.toString())

      try {
        const res = await SportService.betOdinar(item.market.pk, formData)
        if (res.status === 200) {
          window.dispatchEvent(new Event('storage'))
          callbackSuccess?.()
        }
      } catch (err: any) {
        errorAlert(err)

        if (err?.response?.data?.detail?.includes('Not Found or empty market')) {
        } else {
          newCart.push(item)
        }
      }

      await processBetPromises(index + 1)
    }
  }

  await processBetPromises(0)
}

export const addMarketCart = (
  data: ISport.IGame,
  name: string,
  type: string = 'any',
  market_odd: number = 0
) => {
  if (data.tournament) {
    const cart_local = localStorage.getItem('cart')
    let cart = []
    if (cart_local) {
      cart = JSON.parse(cart_local)
    }

    //TODO
    const market = data?.markets?.find(market => {
      switch (type) {
        case 'total over':
          return market.name_en.includes(`Total Over (${market_odd})`)
        case 'win and total':
          return market.name_en.includes(`Win And Total`)
        case 'total under':
          return market.name_en.includes(`Total Under (${market_odd})`)
        case 'handicap 1':
          return (
            market.name_en.includes(`Handicap 1 (${market_odd})`.replace('-', '')) ||
            market.name_en.includes(`Handicap 1 (-${market_odd})`) ||
            market.name_en.includes(`Handicap 1 (${market_odd})`)
          )

        case 'handicap 2':
          return (
            market.name_en.includes(`Handicap 2 (${market_odd})`.replace('-', '')) ||
            market.name_en.includes(`Handicap 2 (-${market_odd})`) ||
            market.name_en.includes(`Handicap 2 (${market_odd})`)
          )
        default:
          return market.name_en.includes(name)
      }
    })

    if (cart.length > 0) {
      let find_game = false
      cart.map((item: any) => {
        if (item.main_const_sport_event_id === data.main_const_sport_event_id) {
          item.market = market
          find_game = true
        }
        return item
      })
      if (!find_game) {
        cart.push({
          main_const_sport_event_id: data.main_const_sport_event_id,
          opponent_one_image: data.opponent_one_image,
          opponent_two_image: data.opponent_two_image,
          start_date: data.start_date,
          opponent_one_name_en: data.opponent_one_name_en,
          opponent_one_name_cn: data.opponent_one_name_cn,
          opponent_two_name_en: data.opponent_two_name_en,
          opponent_two_name_cn: data.opponent_two_name_cn,
          market: market,
          tournament: data.tournament
        })
      }
    } else {
      cart = [
        {
          main_const_sport_event_id: data.main_const_sport_event_id,
          opponent_one_image: data.opponent_one_image,
          opponent_two_image: data.opponent_two_image,
          start_date: data.start_date,
          opponent_one_name_en: data.opponent_one_name_en,
          opponent_one_name_cn: data.opponent_one_name_cn,
          opponent_two_name_en: data.opponent_two_name_en,
          opponent_two_name_cn: data.opponent_two_name_cn,
          market: market,
          tournament: data.tournament
        }
      ]
    }
    localStorage.setItem('cart', JSON.stringify(cart))
    window.dispatchEvent(new Event('storage'))
  }
}
