// Библиотечные компоненты
import { FC } from 'react'

import { LangChanger } from '@/features/header/ui'

import { NavMenuItem } from '@/entities/header'

import { LinkItemMenuProps } from '@/shared/types/global.types'
import { IUsers } from '@/shared/types/user.types'

import styles from './NavMenu.module.scss'

interface IProps {
  dataTabMenu: Array<LinkItemMenuProps>
  userData?: IUsers.UserRating
}

export const NavMenu: FC<IProps> = ({ dataTabMenu, userData }) => {
  return (
    <>
      <div className={styles.wrapper}>
        {dataTabMenu.map((item_menu, index) => {
          if (item_menu.link === '/lang')
            return (
              <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
                <LangChanger />
              </div>
            )

          return (
            <NavMenuItem
              key={item_menu.link}
              data={item_menu}
              // index={index}
              userData={userData}
            />
          )
        })}
      </div>
    </>
  )
}
