import React, { useEffect, useState } from 'react'

export const useMatomo = () => {
  const [ariaId, setAriaId] = useState<string | null>(null)

  useEffect(() => {
    // Создаем MutationObserver для отслеживания изменений атрибутов body
    const observer = new MutationObserver(() => {
      const currentAriaId = document.body.getAttribute('aria-id')
      if (currentAriaId) {
        setAriaId(currentAriaId) // Устанавливаем ariaId в состояние
      }
    })

    // Запускаем наблюдение за изменениями атрибутов у body
    observer.observe(document.body, {
      attributes: true, // Отслеживаем только изменения атрибутов
      attributeFilter: ['aria-id'] // Следим только за aria-id
    })

    // Очищаем наблюдатель при размонтировании компонента
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (ariaId) {
      //@ts-ignore

      let paq = window._paq
      paq = paq || []
      paq.push(['setUserId', ariaId]) // Динамически подставляем user_id
      paq.push(['trackPageView'])
    }
  }, [ariaId])
}
