import { useQuery } from '@tanstack/react-query'

import GamesService from '@/shared/services/games.service'

import { IGames } from '@/shared/types/games.types'

export const useGameSession = (data: IGames.ISessionData) => {
  return useQuery(['gameSession'], () => GamesService.getSession(data), {
    select: ({ data }) => data,
    cacheTime: 0
  })
}
