import { useLayoutEffect, useState } from 'react'

import { useGetScreenResolution } from '@/shared/hooks/useGetScreenResolution'

/**
 * Возвращает объект со св-ом isMobile в значении true, если размер окна меньше 768px (по умолчанию).
 * @param size принимает число пикселей, меньше которых будет считаться мобильным устройством.
 * */
export function useIsMobile(size = 768) {
  const { width } = useGetScreenResolution()
  const [isMobile, setIsMobile] = useState(width <= size)

  useLayoutEffect(() => {
    setIsMobile(width <= size)
  }, [width])

  return {
    isMobile
  }
}
