import { FC, ReactNode } from 'react'

import { QueryClientProvider as TanStackQueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { queryClient } from '@/shared/lib/react-query'

type QueryClientProviderProps = {
  children: ReactNode
}

export const QueryClientProvider: FC<QueryClientProviderProps> = ({ children }) => {
  const isDev = process.env.NODE_ENV === 'development'
  return (
    <TanStackQueryClientProvider client={queryClient}>
      {children}
      {isDev && (
        <ReactQueryDevtools
          initialIsOpen={false}
          errorTypes={[
            {
              name: '422',
              initializer: () => ({
                status: 422,
                error: {
                  errors: {
                    field1: ['error message 1', 'error message 2'],
                    field2: ['error message 3']
                  }
                }
              })
            },
            {
              name: '500',
              initializer: () => ({
                status: 500,
                error: {
                  message: 'Internal server error'
                }
              })
            }
          ]}
        />
      )}
    </TanStackQueryClientProvider>
  )
}
