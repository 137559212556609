import { typeAction, typeDefaultStateShop } from '../types'

const defaultStateShop: typeDefaultStateShop = {
  nowCode: undefined,
  items: [],
  total_count: 0
}

export default function shop(state = defaultStateShop, action: typeAction) {
  const { type, value } = action
  switch (type) {
    case 'SET_SHOP_LIST':
      return { ...value }
    case 'SET_SHOP_ITEM':
      return { ...state, nowCode: value }
    default:
      return { ...state }
  }
}
