import React, { FC } from 'react'

import clsx from 'clsx'

import { TQuiz } from '@/widgets/quiz'

import { QuizContent } from '@/entities/quiz-content'

import styles from './quizLayout.module.scss'

interface IProps extends TQuiz {
  image: string
  title?: string
}
export const QuizLayoutTwo: FC<IProps> = ({ onNextStep, image, title }) => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx('animate__animated animate__backInDown', styles.image)}>
        <img src={image} alt='img' />
      </div>

      <QuizContent title={title} maxWidth='717px' onNextStep={onNextStep} />
    </div>
  )
}
