import { FC, Suspense } from 'react'

import { GameWindow } from '@/widgets/games'

import { useIsMobile, useTranslationField } from '@/shared/hooks'
import Layout from '@/shared/ui/layout'
import { Loader } from '@/shared/ui/loader'

import '@/Assets/sass/pages/Sports/Games.sass'

export const GameDetail: FC = () => {
  const { isMobile } = useIsMobile(1180)
  const { getFieldStatic } = useTranslationField()

  return (
    <Suspense fallback={<Loader />}>
      <Layout
        title='game_title'
        description='game_description'
        titlePage='game_title'
        descriptionMeta='game_description'
        position='center'
        fullWidth={isMobile}
        menuName={getFieldStatic('game_title')}>
        <>
          <GameWindow />
        </>
      </Layout>
    </Suspense>
  )
}
