import { AxiosError } from 'axios'

import { setMessage } from '@/app/store/actions/message'

import { useAppDispatch } from '@/shared/model'

/*
 * @return {object} - Объект, содержащий функцию addErrorAlert для вывода ошибки.
 */
export function useErrorAlert() {
  const dispatch = useAppDispatch()

  const addErrorAlert = (error: AxiosError<any>) => {
    const message = error?.response?.data?.detail || (error?.message as string)

    if (error?.response?.status === 500) {
      dispatch(
        setMessage({
          message: message || 'Service is temporarily down',
          type: 'error'
        })
      )
    } else {
      const statusCode = error?.response?.status
      dispatch(
        setMessage({
          message: message || `message_${statusCode}`, // Некорректная строка, нужно заменить на реальный перевод
          type: 'error'
        })
      )
    }
  }

  return { addErrorAlert }
}
