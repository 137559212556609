import { useEffect, useRef, useState } from 'react'

/*
 * @param {boolean} initialValue - Начальное состояние активности элемента (true - активен, false - не активен).
 * @param {function} onClose - Функция, которая вызывается при клике вне элемента.
 * @param {boolean} nextClick - Флаг, указывающий на то, что следующий клик должен игнорироваться (используется для предотвращения закрытия при клике на элемент).
 * @return {object} - Объект, содержащий:
 *   - ref: Ссылка на DOM-элемент, для которого отслеживается клик вне.
 *   - isActive: Текущее состояние активности элемента (true - активен, false - не активен).
 *   - setIsActive: Функция для обновления состояния активности элемента.
 */
const useOutsideClick = (initialValue: boolean, onClose?: () => void, nextClick?: boolean) => {
  const [isNextClick, setNextClick] = useState(nextClick)
  const [isActive, setIsActive] = useState(initialValue)
  const ref = useRef<HTMLDivElement>(null)

  const handleClick = (e: globalThis.MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node) && !isNextClick) {
      setIsActive(false)
      onClose?.()
    } else {
      setIsActive(true)
    }
    setNextClick(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return { ref, isActive, setIsActive }
}

export default useOutsideClick
