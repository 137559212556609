import { FC, useState } from 'react'

import clsx from 'clsx'

import useOutsideClick from '@/shared/hooks/useOutsideClick'

import { ArrowDown } from '@/Assets/svg'

import { MyOptionType } from '../form/form.interface'

import styles from './dropdown.module.scss'

interface IProps {
  options: MyOptionType[]
  onSelect: (item: MyOptionType) => void
  defaultValue: string
}

export const Dropdown: FC<IProps> = ({ options, defaultValue, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<string | null>(defaultValue || null)
  const { ref } = useOutsideClick(false, () => setIsOpen(false))

  const onSelectValues = (item: MyOptionType) => {
    setSelected(item.value)
    onSelect(item)
    setIsOpen(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} onClick={() => setIsOpen(prev => !prev)} ref={ref}>
        <span>{selected}</span>
        <ArrowDown />
      </div>
      <div className={clsx(styles.bodyWrapper, isOpen && styles.open)}>
        <div className={clsx(styles.body)}>
          {options.map(item => (
            <div
              className={clsx(styles.item, selected === item.value && styles.selected)}
              key={item.value}
              onClick={() => onSelectValues(item)}>
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
